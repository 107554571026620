@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

:root {
  --toastify-color-light: #f8f8f8;
  --toastify-color-dark: #171717;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  font-family: 'Poppins', sans-serif;
  list-style: none;
}

.Toastify__toast-container {
  max-width: 450px;
  width: auto;
}
